import { UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Image, Modal, Row, Spin, Upload, UploadProps } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import TooltipMultipleParagraph from 'app/components/TooltipMultipleParagraph';
import { getListTestStepRunAPI, getTestStepRunDetailAPI, updateTestStepRun } from 'app/services/api/execute';
import { useAppDispatch, useAppSelector } from 'app/store';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { setTestStepDetailDefect, setTestStepId, triggerIsUpdateTestCase } from '../../Execute.slice';
import { ITestStepRun, statusTestStep } from '../../Execute.type';
import './ExecuteTestCase.scss';

const ExecuteTestCase = () => {
  // const testCaseSelectedId = useAppSelector(state => state.execute.testCaseSelectedId);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const url = new URLSearchParams(location.search);
  const testCaseSelectedId: any = url.get('testCaseSelectedId');
  const testCaseRunId: any = url?.get('testCaseRunId');

  const [previewImage, setPreviewImage] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileObject, setFileObject] = useState<any>();
  const [source, setSource] = useState<any>();
  const [testStepDeatil, setTestStepDeatil] = useState<ITestStepRun>();
  const [idNextTestStep, setIdNextTestStep] = useState<number | null>(null);
  const [idPreviousStep, setIdPreviousStep] = useState<number | null>(null);
  const [formData, setFormData] = useState<any>();
  const testStepDetailDefect = useAppSelector(state => state.execute.testStepDetailDefect);
  const [actualResult, setActualResult] = useState<string>();
  const [testStepRunID, setTestStepRunID] = useState<number | string | null>(url.get('testStepRunID'));
  const [testCaseDetailTitle, setTestCaseDetailTitle] = useState<string>('');

  const props: UploadProps = {
    beforeUpload: file => {
      setPreviewImage(URL.createObjectURL(file));
      return false;
    },
  };
  const handleTitleTestCase = useCallback(async () => {
    if (testCaseRunId) {
      const res = await getListTestStepRunAPI(testCaseRunId, testCaseSelectedId);
      setTestCaseDetailTitle(res?.testCaseTitle);
    }
  }, [testCaseRunId, testCaseSelectedId]);

  useEffect(() => {
    handleTitleTestCase();
  }, [handleTitleTestCase]);

  const handleChangeUpload = event => {
    const url = URL.createObjectURL(event.file);
    setFileObject(event.file);
    setFormData(pre => ({ ...pre, attachment: event.file }));
    setSource(url);
  };

  useEffect(() => {
    if (isLoading || !formData) return;
    setIsLoading(true);
    form.validateFields().then(async () => {
      let newFormData: any = new FormData();
      newFormData.append('TestStepRunId', testStepDeatil?.testStepRunId);
      newFormData.append(
        'StatusType',
        formData?.statusType == 'Not Run' ? 'Not Started' : formData?.statusType ?? testStepDeatil?.statusType,
      );
      newFormData.append('ActualResult', formData?.actualResult ?? testStepDeatil?.actualResult ?? '');
      newFormData.append('IsUpdateAttachment', false);
      newFormData.append('Attachment', formData?.attachment);
      try {
        const res = await updateTestStepRun(newFormData);
        dispatch(triggerIsUpdateTestCase());
        // localStorage.setItem(`event`, Date.now().toString());
        openNotificationWithIcon('success', res.message);
        setIsLoading(false);
      } catch (error: any) {
        openNotificationWithIcon('error', error?.message ? error.message : 'File format is not valid');
        setIsLoading(false);
      }
    });
  }, [formData, testStepDeatil]);

  const handleChangeActualResult = e => {
    setActualResult(e.target.value);
  };

  const handleBlurActualResult = e => {
    setFormData(pre => ({ ...pre, actualResult: e.target.value }));
  };

  /* Hanlde Next Or Prev Step */
  const handleNextOrPrevTestCase = (type: string) => {
    setFormData(undefined);
    const idToSet = type === 'next' ? idNextTestStep : idPreviousStep;
    if (idToSet) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('testStepRunID', String(idToSet));
      setTestStepRunID(idToSet);
      const newURL = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState({}, '', newURL);
    }
  };

  const getTestStepRunDeatil = async () => {
    try {
      const res = await getTestStepRunDetailAPI(testStepRunID as any, testCaseRunId as any);
      setTestStepDeatil(res?.data);
      dispatch(setTestStepDetailDefect(res?.data));
      localStorage.setItem(`testStepDetailDefect`, JSON.stringify(res?.data));
      setIdNextTestStep(res?.nextStep);
      setIdPreviousStep(res?.previousStep);
      setActualResult(res?.data?.actualResult);
      setPreviewImage(res.data?.attachmentUrl);
    } catch (error) {}
  };
  const openModalCreateDefect = () => {
    localStorage.setItem(`open-modal`, `open-modal-${Date.now().toString()}`);
    localStorage.setItem(`actualResult`, actualResult as any);
    window.close();
  };

  const handleChangeStatus = (value: string) => {
    setFormData(pre => ({ ...pre, statusType: value == 'Not Run' ? 'Not Started' : value }));
  };

  useEffect(() => {
    if (testStepRunID) {
      getTestStepRunDeatil();
    }
  }, [testStepRunID]);

  useEffect(() => {
    setIsOpen(!isOpen);
    window.localStorage.removeItem('open-modal');
    localStorage.removeItem('event');
    const urlParams = new URLSearchParams(location.search);
    const newTestStepID = urlParams.get('testStepRunID');
    setTestStepRunID(newTestStepID);
  }, []);

  return (
    <>
      <Modal
        className="execute-manual-test-case"
        open={isOpen}
        // onCancel={cancel}
        destroyOnClose={true}
        footer={null}
        closeIcon={''}
      >
        <Helmet>
          <title>Execute</title>
        </Helmet>
        <Spin style={{ marginTop: ` ${!previewImage ? '20%' : '50%'}`, zIndex: 900 }} spinning={isLoading}>
          <div className="test-case-steps__modal">
            <div className="add-new-defect-modal">
              <div className="border-0 mb-6">
                <div className="mt-5">
                  <h3 className="add-new-modal__header" role="title">
                    {testCaseSelectedId} - {testCaseDetailTitle}
                  </h3>
                </div>
              </div>

              <div className="test-case_modal_container">
                <Card>
                  <Form
                    form={form}
                    // onFinish={onFinish}
                    className="release-detail__form layout-dashboard "
                    autoComplete="off"
                  >
                    <Row className="">
                      <Col span={24}>
                        <span className="test-case__step_upload_title">Step ID: {testStepDeatil?.testStepId}</span>
                      </Col>

                      <Col span={24} className="test-case__step_upload">
                        <Row className="test-case__step_upload-contain">
                          <Col className="step_upload__item">
                            <Row className="attachment_preview_img">
                              {previewImage ? (
                                <Image width={300} height={300} src={previewImage} preview={false} />
                              ) : null}
                            </Row>
                          </Col>
                          <Col className="step_upload__item">
                            <Upload
                              showUploadList={false}
                              beforeUpload={() => false}
                              onChange={handleChangeUpload}
                              id="attachment-modal__upload"
                              accept="image/*"
                              {...props}
                            >
                              <Button
                                className="attachment-modal__upload"
                                icon={<UploadOutlined />}
                                type="primary"
                                value={source}
                                id="execute-test-case__upload"
                              >
                                Upload
                              </Button>
                            </Upload>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="modal_container-action" gutter={[8, 8]}>
                      <Col style={{ marginTop: 15 }} span={12}>
                        <span className="test-case__step__title">Activity</span>
                        <TooltipMultipleParagraph
                          disable={true}
                          color="#6bb2d6"
                          rows={5}
                          className="testcase__multiple_paragraph"
                          width="100%"
                          children={testStepDeatil?.testStepActivity}
                        />
                      </Col>
                      <Col style={{ marginTop: 15 }} span={12}>
                        <span className="test-case__step__title">Additional Information</span>
                        <TooltipMultipleParagraph
                          disable={true}
                          color="#6bb2d6"
                          rows={5}
                          className="testcase__multiple_paragraph"
                          width="100%"
                          children={testStepDeatil?.testStepAdditionalInformation}
                        />
                      </Col>
                    </Row>

                    <Row className="modal_container-action" gutter={[12, 12]}>
                      <Col span={18}>
                        <Row style={{ width: '100%' }}>
                          <span className="test-case__step__title">Expected Result</span>
                          <TooltipMultipleParagraph
                            disable={true}
                            color="#6bb2d6"
                            rows={5}
                            className="testcase__multiple_paragraph"
                            width="100%"
                            children={testStepDeatil?.testStepExpectedResult}
                          />
                        </Row>
                        <Row style={{ marginTop: 10, width: '100%' }}>
                          <span className="test-case__step__title">Actual Result</span>
                          <TextArea
                            id="execution-detail__textArea"
                            className="execution-detail__textArea"
                            onChange={handleChangeActualResult}
                            onBlur={handleBlurActualResult}
                            rows={4}
                            value={actualResult}
                          />
                          <div className="step_action-button__actual">
                            <Button
                              id="execution-test-step__prev"
                              disabled={!idPreviousStep}
                              onClick={() => handleNextOrPrevTestCase('prev')}
                              className={`button ${!idPreviousStep && 'button_prev'}`}
                            >
                              Prev Step
                            </Button>
                            <Button
                              onClick={openModalCreateDefect}
                              className={`button`}
                              id="execution-test-step__create-defect"
                            >
                              Create Defect
                            </Button>
                            <Button
                              id="execution-test-step__next"
                              disabled={!idNextTestStep}
                              onClick={() => handleNextOrPrevTestCase('next')}
                              className={`button ${!idNextTestStep && 'button_prev'}`}
                            >
                              Next Step
                            </Button>
                          </div>
                        </Row>
                      </Col>
                      <Col span={2} />
                      <Col span={4}>
                        <div className="step_action-button">
                          {statusTestStep.map((status, index) => {
                            return (
                              <>
                                <Button
                                  id={`execution-test-step__${status?.value}`}
                                  key={index}
                                  htmlType="submit"
                                  className="button"
                                  style={{ backgroundColor: status.color }}
                                  onClick={() => handleChangeStatus(status?.name)}
                                >
                                  {status?.name}
                                </Button>
                              </>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default ExecuteTestCase;
