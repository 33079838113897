import { createSlice } from '@reduxjs/toolkit';

export interface NotificationState {
  notificationNumber: string;
  isUpdateMiniDashBoard: boolean;
}

const initialState: any = {
  notificationNumber: '0',
  isUpdateMiniDashBoard: false,
};

export const notificationSlide = createSlice({
  name: 'notificationSlide',
  initialState,
  reducers: {
    setNotificationNumber: (state, action) => {
      state.notificationNumber = action.payload;
    },
    setIsUpdateMiniDashBoard: (state, action) => {
      state.isUpdateMiniDashBoard = action.payload;
    },
  },
});

export const { setNotificationNumber, setIsUpdateMiniDashBoard } = notificationSlide.actions;

export default notificationSlide.reducer;
