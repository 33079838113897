import { ModuleName } from './models/permission.model';

export enum SelectionTypeReleaseScope {
  All = 1,
  Inclusive = 2,
  Exclusive = 3,
}

export enum EntityType {
  Testcase = 1,
  Release = 2,
  AcceptanceCriteria = 3,
  Requirement = 4,
  Defect = 5,
  Batch = 6,
  DynamicImage = 7,
  Item = 8,
  Scenario = 9,
  TestCondition = 10,
  BusinessAcceptanceArticle = 11,
}

export enum EXTENSION_ACTION {
  Login = 'login',
  Logout = 'logout',
  Open = 'open',
}

export const ModuleItem = {
  [ModuleName.Worklist]: {
    path: '/home',
  },
  [ModuleName.Release]: {
    path: '/release',
  },
  [ModuleName.UserStories]: {
    path: '/requirement',
  },
  [ModuleName.Defect]: {
    path: '/defect',
  },
  [ModuleName.Workbench]: {
    path: '/test-case',
  },
  [ModuleName.TestLab]: {
    path: '/testlab',
  },
  [ModuleName.Document]: {
    path: '/document-management',
  },
  [ModuleName.Execute]: {
    path: '/execute',
  },
  [ModuleName.EnginuityWorkbench]: {
    path: '/enginuity-workbench',
  },
  [ModuleName.AuthoriseWorkbench]: {
    path: '/authorise-workbench',
  },
  [ModuleName.Report]: {
    path: '/reports',
  },
  [ModuleName.BatchScheduling]: {
    path: '/enginuity-batch',
  },
  [ModuleName.AuthoriseBatch]: {
    path: '/authorise-batch',
  },
  [ModuleName.VirtualTesterMonitor]: {
    path: '/virtual-tester-monitor',
  },
  [ModuleName.ServiceCatalogue]: {
    path: '/service-catalogue',
  },

  [ModuleName.AcceptanceCriteria]: {
    path: '/acceptance-criteria',
  },
  [ModuleName.Acceptance]: {
    path: '/my-dashboard',
  },
  [ModuleName.BatchAcceptanceScheduling]: {
    path: '/authorise-batch',
  },
  [ModuleName.Dashboard]: {
    path: '/dashboard',
  },
  [ModuleName.TestDesign]: {
    path: '/test-design',
  },
  [ModuleName.AIML]: {
    path: '/ai-ml',
  },
  [ModuleName.DecisionTable]: {
    path: '/decision-table',
  },
  [ModuleName.AdminFunction]: {
    path: '/setting',
  },
  [ModuleName.LicenceManagement]: {
    path: '/licence-management',
  },
};

export const saveDataToLocalStorage = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};
