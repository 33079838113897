import client from '.';

export const getRequirementGridChartAPI = params => {
  return client.get('/RequirementRegister/get-requirement-grid-chart', { params: params }).then(res => res.data);
};

export const getRequirementDetailAPI = (id: string) => {
  return client.get('/Requirement/get-requirement-detail', { params: { id } });
};

export const getRequirementTypeAPI = () => {
  return client.get('/Requirement/get-requirement-type').then(res => res.data);
};

export const getisoClassificationAPI = () => {
  return client.get('/Requirement/get-iso-classification').then(res => res.data);
};

export const getAPIRequirementID = (id: number) => {
  return client.get(`Common/get-new-id?type=${id}`).then(res => res.data);
};

export const getsubClassificationAPI = (value: string) => {
  return client.get(`/Requirement/get-sub-classification?isoClassificationDescription=${value}`).then(res => res.data);
};

export const addNewRequirementAPI = (param: any) => {
  return client.post('/Requirement/create-requirement', { ...param });
};

export const updateRequirementAPI = (param: any) => {
  return client.post('/Requirement/update-requirement', { ...param });
};

export const deleteRequirementAPI = params => {
  return client.delete('/Requirement/delete-requirement', { data: params });
};

// Workflow
export const getWorkflowAPI = () => {
  return client.get(`/Requirement/get-requirement-workflow`).then(res => res.data);
};

export const getWorkflowDraftAPI = (id: number, comment: string) => {
  return client.post(`Requirement/requirement-workflow-draft?id=${id}&comment=${comment}`).then(res => res.data);
};

export const getWorkflowReadyForReviewAPI = (param: any) => {
  return client.post(`Requirement/requirement-workflow-readyforreview`, { ...param }).then(res => res.data);
};

export const getWorkflowReworkRequiredAPI = (param: any) => {
  return client.post(`Requirement/requirement-workflow-rework`, { ...param }).then(res => res.data);
};

export const getWorkflowApprovedAPI = (id: number) => {
  return client.post(`Requirement/requirement-workflow-approved?id=${id}`).then(res => res.data);
};

export const getWorkflowDeprecateAPI = (param: any) => {
  return client.post(`Requirement/requirement-workflow-deprecate`, { ...param }).then(res => res.data);
};

export const getWorkflowAssignAPI = (title: string, param: any) => {
  return client.post(`/Requirement/requirement-workflow-${title}`, { ...param }).then(res => res.data);
};

// Chart API ......

export const apiGetMiniDashboard = (id: number) => {
  return client.get(`Requirement/get-mini-dashboard?id=${id}`).then(res => res.data);
};

export const excutionStatusAPI = (id: number) => {
  return client.get(`Requirement/get-execution-status-v2?id=${id}`).then(res => res.data);
};

export const issueStatusAPI = (id: number) => {
  return client.get(`Requirement/get-issue-status?id=${id}`).then(res => res.data);
};

export const getRequirementHistoryAPI = (requirementId: number) => {
  return client.get('/Requirement/get-histories', { params: { id: requirementId } });
};

export const getRequirementTestCoverageAPI = params => {
  return client.get('/RequirementTestCoverage/get-requirement-test-coverage', { params: params });
};

export const assignTestCaseAPI = params => {
  return client.post('/RequirementTestCoverage/assign-testcase', params);
};

export const deleteTestCaseAPI = params => {
  return client.post('/RequirementTestCoverage/delete-testcase', params);
};

// requirement folder view api
export const getRequirementFolderViewAPI = (searchString: string, isAssignToMe: boolean) => {
  return client
    .get(`/RequirementRegister/get-requirement-folder-chart?isAssignToMe=${isAssignToMe}`, { params: { searchString } })
    .then(res => res.data);
};

export const assignRequirementToFolderAPI = params => {
  return client.post('/RequirementRegister/assign-requirement-to-folder', params);
};

export const reparentRequirementAPI = params => {
  return client.post('/RequirementRegister/reparent-requirement', params);
};

export const getUserStoriesMiniDashboardAPI = () => {
  return client.get('/RequirementRegister/get-mini-dashboard').then(res => res.data);
};

// end requirement folder view api
