import React, { useCallback, useEffect, useState } from 'react';
import './Layout.scss';
import { Col, Menu, Row, Select, Typography } from 'antd';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import ReleaseIcon from '../../assets/images/icons/release.svg';
import UserStoriesIcon from '../../assets/images/icons/user-story.svg';
import TestDesignIcon from '../../assets/images/icons/test-design-icon.svg';
import WorkbenchIcon from '../../assets/images/icons/workbench.svg';
import EnginuityWorkbenchhIcon from '../../assets/images/icons/enginuity-workbench.svg';
import TestLabIcon from '../../assets/images/icons/test-lab.svg';
import ExecuteIcon from '../../assets/images/icons/execute.svg';
import BugsIcon from '../../assets/images/icons/bug.svg';
import ReportIcon from '../../assets/images/icons/report.svg';
import HomeIcon from '../../assets/images/icons/home-icon.svg';
import BatchIcon from '../../assets/images/icons/batch-scheduling-icon.svg';
import AuthoriseWorkbenchIcon from '../../assets/images/icons/authorise-workbench.svg';
import AuthoriseBatchIcon from '../../assets/images/icons/clipboard-tick.svg';
import VTMIcon from '../../assets/images/icons/VTM-icon.svg';
import DashboardIcon from '../../assets/images/icons/dashboard.svg';
import AcceptanceCriteriaIcon from '../../assets/images/icons/acceptancecriteria.svg';
import AcceptanceArticleIcon from '@src/assets/images/icons/acceptance-article-icon.svg';
import ServiceCatalogueIcon from '../../assets/images/icons/service-catalogue.svg';
import AIMenuIcon from '@src/assets/images/icons/ai-menu-icon.svg';
import DecisionTableIcon from '@src/assets/images/icons/decision-table-icon.svg';
import DocumentConvertIcon from '@src/assets/images/icons/document-convert-icon.svg';

import { INotification, INotificationType } from 'types/Dashboard/INotification';
import { getListProjectAPI, selectProjectAPI } from 'app/services/api/project';
import { IProject } from 'types/Dashboard/IProject';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import { useAppDispatch, useAppSelector } from 'app/store';
import { triggerOpenModalConfirm, triggerUpdateTestStep } from 'app/pages/Workbench/Workbench.slice';
import { triggerNextSlide } from 'app/pages/EnginuityWorkbench/Workbench.slice';
import { usePermission } from 'app/hooks/usePermission.hook';
import { ActionType, ModuleName } from 'app/models/permission.model';
import { setUserPermissions } from 'app/pages/LoginPage/auth.slice';
import { getListNotificationAPI } from 'app/services/api/home';
import { setNotificationNumber } from 'app/pages/HomePage/Notifications/Notification.slide';

const menuLink = (key, label, link, icon, prevPage = '', noti?) => {
  return (
    <NavLink
      to={{
        pathname: link,
        state: { prevPage },
      }}
      style={{ display: 'flex', alignItems: 'end', padding: '5px 16px' }}
      id={`menu-item__${key}`}
    >
      <Col>
        <img src={icon} style={{ width: 15 }} className="menu-icon" alt="label" />
      </Col>
      <Col span={8} className="label" style={{ marginLeft: 5 }}>
        {label}
      </Col>
      {key === 'worklist' && (
        <Col span={8} className="label" style={{ marginLeft: 5 }}>
          {noti}
        </Col>
      )}
    </NavLink>
  );
};
function Sidenav({ color }) {
  const location = useLocation();
  const history = useHistory();
  const [listProject, setListProject] = useState<IProject[]>();
  const [currentProjectID, setCurrentProjectID] = useState<number>();
  const [listNotification, setListNotification] = useState<INotification[]>([]);
  const isChangeTestStep = useAppSelector(state => state?.workbench?.isChangeTestStep);
  const isOpenModalConfirm = useAppSelector(state => state?.workbench?.isOpenModalConfirm);
  const notificationNumber = useAppSelector(state => state?.notification?.notificationNumber);
  const selectProduct = useAppSelector(state => state?.auth?.selectProduct);

  const dispatch = useAppDispatch();
  const { isHavePermission } = usePermission();
  const { Option } = Select;

  const itemsMSA: any['items'] = [
    {
      label: menuLink('worklist', 'Worklist', '/home', HomeIcon, undefined, notificationNumber),
      key: 'home',
      isHavePerission: true,
    },
  ].filter(x => x?.isHavePerission);

  const items = [
    {
      label: (
        <Row className="submenu-title">
          <Typography.Text>QMFactory</Typography.Text>
        </Row>
      ),
      key: 'qmFactory',
      value: 'QMF',
      children: [
        // {
        //   label: menuLink('worklist', 'Worklist', '/home', HomeIcon, undefined, notificationNumber),
        //   key: 'home',
        //   isHavePerission: true,
        // },
        {
          label: menuLink('dashboard', 'Dashboard', '/dashboard', DashboardIcon),
          key: 'dashboard',
          // className: 'menu-disabled',
          isHavePerission: isHavePermission(ModuleName.Dashboard, ActionType.VIEW),
        },
        {
          label: menuLink('release', 'Release', '/release', ReleaseIcon),
          key: 'release',
          isHavePerission: isHavePermission(ModuleName.Release, ActionType.VIEW),
        },
        {
          label: menuLink('userstories', 'User Stories', '/requirement', UserStoriesIcon),
          key: 'requirement',
          isHavePerission: isHavePermission(ModuleName.UserStories, ActionType.VIEW),
        },
        {
          label: menuLink('documentconvert', 'Document', '/document-convert', DocumentConvertIcon),
          key: 'documentconvert',
          isHavePerission: isHavePermission(ModuleName.Document, ActionType.VIEW),
        },
        {
          label: menuLink('decisiontable', 'Decision Table', '/decision-table', DecisionTableIcon),
          key: 'decisiontable',
          isHavePerission: isHavePermission(ModuleName.DecisionTable, ActionType.VIEW),
        },
        {
          label: menuLink('testdesign', 'Test Design', '/test-design', TestDesignIcon),
          key: 'testDesign',
          isHavePerission: isHavePermission(ModuleName.TestDesign, ActionType.VIEW),
        },
        {
          label: menuLink('workbench', 'Workbench', '/test-case', WorkbenchIcon),
          key: 'workbench',
          isHavePerission: isHavePermission(ModuleName.Workbench, ActionType.VIEW),
        },
        {
          label: menuLink('testlab', 'Test Lab', '/testlab', TestLabIcon),
          key: 'testlab',
          // className: 'menu-disabled',
          isHavePerission: isHavePermission(ModuleName.TestLab, ActionType.VIEW),
        },
        {
          label: menuLink('execute', 'Execute', '/execute', ExecuteIcon),
          key: 'execute',
          // className: 'menu-disabled',
          isHavePerission: isHavePermission(ModuleName.Execute, ActionType.VIEW),
        },
        {
          label: menuLink('bugs', 'Defect', '/defect', BugsIcon),
          key: 'defect',
          isHavePerission: isHavePermission(ModuleName.Defect, ActionType.VIEW),
        },
        {
          label: menuLink('reports', 'Reports', '/reports', ReportIcon),
          key: 'reports',
          isHavePerission: isHavePermission(ModuleName.Report, ActionType.VIEW),
        },
      ],
    },
    {
      label: (
        <Row className="submenu-title">
          <Typography.Text>Enginuity</Typography.Text>
        </Row>
      ),
      key: 'enginuity',
      value: 'ENG',
      children: [
        {
          label: menuLink('ai_ml', 'AI & ML', '/ai-ml', AIMenuIcon, location.pathname),
          key: 'ai-ml',
          isHavePerission: isHavePermission(ModuleName.AIML, ActionType.VIEW),
        },
        {
          label: menuLink('enginuityworkbench', 'Enginuity Workbench', '/enginuity-workbench', EnginuityWorkbenchhIcon),
          key: 'enginuityworkbench',
          isHavePerission: isHavePermission(ModuleName.EnginuityWorkbench, ActionType.VIEW),
        },
        {
          label: menuLink('batch', 'Enginuity Batch', '/enginuity-batch', BatchIcon),
          key: 'batchscheduling',
          isHavePerission: isHavePermission(ModuleName.BatchScheduling, ActionType.VIEW),
        },
        {
          label: menuLink('virtualtestermonitor', 'Virtual Tester Monitor', '/virtual-tester-monitor', VTMIcon),
          key: 'virtualtestermonitor',
          isHavePerission: isHavePermission(ModuleName.VirtualTesterMonitor, ActionType.VIEW),
        },
      ],
    },
    {
      label: (
        <Row className="submenu-title">
          <Typography.Text>Authorise</Typography.Text>
        </Row>
      ),
      key: 'authorisation',
      value: 'AUTH',
      children: [
        {
          label: menuLink('acceptancecriteria', 'Acceptance Criteria', '/acceptance-criteria', AcceptanceCriteriaIcon),
          key: 'acceptancecriteria',
          isHavePerission: isHavePermission(ModuleName.AcceptanceCriteria, ActionType.VIEW),
        },
        {
          label: menuLink('acceptancearticle', 'My Dashboard', '/my-dashboard', AcceptanceArticleIcon),
          key: 'acceptancearticle',
          isHavePerission: isHavePermission(ModuleName.Acceptance, ActionType.VIEW),
        },
        {
          label: menuLink('authorisebatch', 'Authorise Batch', '/authorise-batch', AuthoriseBatchIcon),
          key: 'authorisebatch',
          isHavePerission: isHavePermission(ModuleName.AuthoriseBatch, ActionType.VIEW),
        },
        {
          label: menuLink('authoriseworkbench', 'Authorise Workbench', '/authorise-workbench', AuthoriseWorkbenchIcon),
          key: 'authoriseworkbench',
          isHavePerission: isHavePermission(ModuleName.AuthoriseWorkbench, ActionType.VIEW),
        },
      ],
    },
    // {
    //   label: 'Miscs',
    //   className: 'onlyText',
    //   key: 'miscs',
    //   children: [
    //     {
    //       label: menuLink('decisiontable', 'Decision Table', '/decision-table', DecisionTableIcon),
    //       key: 'decisiontable',
    //       isHavePerission: isHavePermission(ModuleName.DecisionTable, ActionType.VIEW),
    //     },
    //     {
    //       label: menuLink('documentconvert', 'Document', '/document-convert', DocumentConvertIcon),
    //       key: 'documentconvert',
    //       isHavePerission: isHavePermission(ModuleName.Document, ActionType.VIEW),
    //     },
    //   ],
    // },
  ];

  const currentItems = items.map((item: any) => {
    // if (selectProduct?.includes(item?.value)) {
    //   return item;
    // }

    if (!item?.children?.length) {
      if (item.isHavePerission) return item;
      return null;
    }

    const newItemChildren = item?.children?.filter(child => child.isHavePerission);
    if (newItemChildren?.length)
      return {
        ...item,
        children: newItemChildren,
      };
    return null;
  });

  const getListNotification = useCallback(async () => {
    await getListNotificationAPI().then(res => setListNotification(res.notifications));
  }, []);

  useEffect(() => {
    getListNotification();
  }, [getListNotification]);

  useEffect(() => {
    getListProject();
  }, []);

  useEffect(() => {
    if (listNotification === null) {
      dispatch(setNotificationNumber(''));
    } else {
      let numberNoti = 0;
      listNotification?.forEach(item => {
        if (!item.isRead) {
          numberNoti++;
        }
      });
      // dispatch(setNotificationNumber(`(${numberNoti}/${listNotification?.length})`));
      dispatch(setNotificationNumber(`(${numberNoti})`));
    }
  }, [dispatch, listNotification]);

  const getListProject = async () => {
    try {
      let res = await getListProjectAPI();
      setListProject(res.projects);
      setCurrentProjectID(res.previousProject?.projectID);
    } catch (error: any) {
      openNotificationWithIcon('error', error.message);
    }
  };
  const handleChangeProject = async id => {
    try {
      let res = await selectProjectAPI(id);
      localStorage.setItem('accessToken', res);
      dispatch(setUserPermissions(res));
      setCurrentProjectID(id);
      history.push('/home');
      window.location.reload();
    } catch (error: any) {
      openNotificationWithIcon('error', error.message);
    }
  };

  const _handleOpenModalConfirm = useCallback(() => {
    if (isChangeTestStep) {
      dispatch(triggerOpenModalConfirm(true));
      dispatch(triggerUpdateTestStep(false));
    }
  }, [isChangeTestStep]);

  const handleClickItem = () => {
    dispatch(triggerNextSlide(false));
  };

  return (
    <div className="sidebar" style={{ paddingBottom: '30px' }}>
      <div className="menu-item-header menu-full-item">Select a project :</div>
      <div className="ant-menu-item menu-full-item">
        <Select
          id="sidebar__select-project"
          className="sidebar__select-project"
          size="large"
          onChange={handleChangeProject}
          value={currentProjectID}
        >
          {listProject?.map(item => (
            <Option id={`sidebar__option__${item.projectID}`} key={item.projectID} value={item.projectID}>
              {item.projectTitle}
            </Option>
          ))}
        </Select>
      </div>
      <hr style={{ marginBottom: 7 }} />
      <div className="menu-item-header menu-full-item" key="5">
        Main Menu
      </div>
      <div className="kt_aside_menu sidebar-dirty-overlay">
        <Menu theme="light" mode="inline" items={itemsMSA} id="sidebar__menu__msa" onClick={handleClickItem} />
      </div>
      <div className="kt_aside_menu sidebar-dirty-overlay">
        <div className="overlay_slidebar" onClick={_handleOpenModalConfirm}></div>
        <Menu
          theme="light"
          mode="inline"
          items={currentItems}
          defaultOpenKeys={['qmFactory', 'enginuity', 'authorisation', 'miscs']}
          id="sidebar__menu__main"
          className="sidebar__menu__main"
          onClick={handleClickItem}
        />
      </div>
    </div>
  );
}

export default Sidenav;
