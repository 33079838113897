/* eslint-disable prettier/prettier */
import { Button, Col, Form, Input, Modal, Row, Select, Tag, Typography } from 'antd';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './ModalUserDeatil.scss';
import { ValidationError, ValidationSchema } from 'app/constants';
import { useAppSelector, RootState } from 'app/store';
import { updateProfileAPI } from 'app/services/api/user';
import { setIsUpdateProfile } from 'app/pages/LoginPage/auth.slice';
const { Text, Title } = Typography;

interface ModalUserDeatilProps {
  onCancel: (value: boolean) => void;
  open: boolean;
  openChangePassword: any;
}

const ModalUserDeatil = ({ onCancel, open, openChangePassword }: ModalUserDeatilProps) => {
  const userDetail = useAppSelector((state: RootState) => state.auth.userDetail);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [value, setValue] = useState('');

  const _onSave = () => {
    try {
      form
        .validateFields()
        .then(async then => {
          const formValues = await form.getFieldsValue();
          const res = await updateProfileAPI({
            ...formValues,
            userId: userDetail?.userId,
          });
          dispatch(setIsUpdateProfile());
          openNotificationWithIcon('success', res?.message);
          handleCancelModal();
        })
        .catch(error => {
          if (error?.message) {
            openNotificationWithIcon('error', error?.message);
          }
        });
    } catch (error: any) {}
  };

  const listRole = useMemo(() => {
    if (userDetail) {
      const newData = userDetail?.userProjects?.map(projects => {
        const roleName = projects?.userProjectRoles.map(role => {
          return role?.roleName;
        });
        return {
          ...projects,
          roleName: roleName,
        };
      });
      return newData;
    }
  }, [userDetail]);

  const _handleChangePassword = () => {
    openChangePassword(true);
    onCancel(false);
  };

  useEffect(() => {
    if (userDetail) {
      const initialForm = {
        firstName: userDetail?.firstName,
        lastName: userDetail?.lastName,
        fullName: userDetail?.userName,
        email: userDetail?.email,
        phoneNumber: userDetail?.phoneNumber,
        // roleTitle: userDetail?.roleTitle,
      };
      form.setFieldsValue(initialForm);
    } else {
      form.resetFields();
    }
  }, [open, userDetail]);

  const _renderFooter = () => {
    return (
      <Row justify="end" gutter={[12, 0]}>
        <Button onClick={handleCancelModal}>Cancel</Button>
        <Button type="primary" style={{ marginRight: 10 }} onClick={_onSave}>
          Save
        </Button>
      </Row>
    );
  };

  const handleCancelModal = () => {
    onCancel(false);
  };

  return (
    <>
      <Modal
        width={800}
        footer={_renderFooter()}
        title="Profile"
        destroyOnClose={true}
        closeIcon={false}
        centered
        open={open}
        onCancel={handleCancelModal}
        style={{ verticalAlign: 'top', marginTop: '100px' }}
        className="user-deatil"
      >
        <Form layout="vertical" form={form} autoComplete="off">
          <Row gutter={[12, 0]}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                required={true}
                rules={[
                  {
                    required: true,
                    message: 'Please enter First Name',
                  },
                ]}
              >
                <Input id="name-modal__input" className="input_username" placeholder="Enter First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                required={true}
                rules={[
                  {
                    required: true,
                    message: 'Please enter Last Name',
                  },
                ]}
              >
                <Input id="name-modal__input" className="input_username" placeholder="Enter Last Name" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="User Name" name="fullName">
                <Input
                  disabled
                  id="name-modal__input"
                  type="text"
                  className="input_username"
                  placeholder="Enter Contact Number"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Email" name="email">
                <Input disabled typeof="" id="name-modal__input" className="input_username" placeholder="Enter email" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Contact Number"
                name="phoneNumber"
                required={true}
                rules={[
                  ({ getFieldValue }) => ({
                    validator() {
                      if (!ValidationSchema.phoneNumber.test(getFieldValue('phoneNumber'))) {
                        return Promise.reject(new Error(ValidationError.phoneNumber));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <PhoneInput country={'vn'} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Role" name="role">
                <div className="role-content">
                  {listRole?.map(pro => {
                    return (
                      <Row style={{ color: '#002060', alignItems: 'center' }} className="mt-5">
                        <div className="pg-dot"></div>
                        <div>{pro?.projectName}: </div>
                        <div>{pro?.roleName?.join(', ')}</div>
                      </Row>
                    );
                  })}
                </div>
              </Form.Item>
            </Col>

            <Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
              <Text
                onClick={_handleChangePassword}
                style={{
                  textDecoration: 'underline',
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#002060',
                  cursor: 'pointer',
                }}
              >
                Change password
              </Text>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalUserDeatil;
