import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { batchExecutionDetailsAPI, minidashboardAPI } from 'app/services/api/batch';
import { BatchItem, BatchSetiing, IBatchGridView } from './AuthoriseBatch.type';

export enum PATH_NAME {
  ENGINUITY = 'enginuity',
  AUTHORISE = 'authorise',
}
export interface AuthoriseBatchState {
  isLoading: boolean;
  batchSelectedId: number;
  isUpdateBatch: boolean;
  batchSelectedDetail?: BatchItem | null;
  batchSetting?: BatchSetiing;
  updateBatchSummary: boolean;
  batchConfiguringDetail: any;
  defectTriageDetail: any;
  isDefectTriage: boolean;
  updateDefectTriage: boolean;
  listMiniDasboard: any;
  listBatchExecutionDetails: any;
  isLoadingMiniDashboard: boolean;
  isLoadingBatchExecution: boolean;
  isDisableTestCaseWithBatchExecutionDetail: boolean;
  isDisableTestCaseWithDashboard: boolean;
  isReloadViewBatchScope: boolean;
  isReloadManageBatchScope: boolean;
  isLoadingAdminMiniDashboard: boolean;
}

const initialState: AuthoriseBatchState = {
  isLoading: false,
  batchSelectedId: 0,
  isUpdateBatch: false,
  batchSelectedDetail: undefined,
  batchConfiguringDetail: undefined,
  batchSetting: undefined,
  updateBatchSummary: false,
  defectTriageDetail: [],
  isDefectTriage: false,
  updateDefectTriage: false,
  listMiniDasboard: [],
  listBatchExecutionDetails: undefined,
  isLoadingMiniDashboard: false,
  isLoadingBatchExecution: false,
  isDisableTestCaseWithBatchExecutionDetail: false,
  isDisableTestCaseWithDashboard: false,
  isReloadViewBatchScope: false,
  isReloadManageBatchScope: false,
  isLoadingAdminMiniDashboard: false,
};

export const getMiniDashboard = createAsyncThunk('batch/getMiniDashboard', async (id: number) => {
  const response = await minidashboardAPI(id);
  return response?.data;
});

export const getBatchExecutionDetails = createAsyncThunk('release/getBatchExecutionDetails', async (id: number) => {
  const response = await batchExecutionDetailsAPI(id);
  return response?.data;
});

export const authoriseBatchSlice = createSlice({
  name: 'authoriseBatch',
  initialState,
  reducers: {
    setBatchSelectedId: (state, action) => {
      state.batchSelectedId = action.payload;
      state.isDefectTriage = false;
    },
    setBatchSelectedDetail: (state, action) => {
      state.batchSelectedDetail = action.payload;
    },
    setBatchConfiguringDetail: (state, action) => {
      state.batchConfiguringDetail = action.payload;
    },
    setBatchSetting: (state, action) => {
      state.batchSetting = action.payload;
    },
    setDefectTriageDetail: (state, action) => {
      state.defectTriageDetail = [...action.payload];
    },
    setViewBatchScren: state => {
      state.isDefectTriage = !state.isDefectTriage;
    },
    triggerUpdateBatch: state => {
      state.isUpdateBatch = !state.isUpdateBatch;
    },
    clearBatchSummary: state => {
      state.batchSelectedDetail = null;
    },
    triggerUpdateBatchSummary: state => {
      state.updateBatchSummary = !state.updateBatchSummary;
    },
    triggerUpdateDefectTriage: state => {
      state.updateDefectTriage = !state.updateDefectTriage;
    },
    triggersLoadingMiniDashboard: state => {
      state.isLoadingMiniDashboard = !state.isLoadingMiniDashboard;
    },
    triggersLoadingBatchExecution: state => {
      state.isLoadingBatchExecution = !state.isLoadingBatchExecution;
    },
    triggersIsDisableTestCaseWithBatchExecutionDetail: state => {
      state.isDisableTestCaseWithBatchExecutionDetail = !state.isDisableTestCaseWithBatchExecutionDetail;
    },
    triggersIsDisableTestCaseWithDashboard: state => {
      state.isDisableTestCaseWithDashboard = !state.isDisableTestCaseWithDashboard;
    },
    triggerReloadViewBatchScope: state => {
      state.isReloadViewBatchScope = !state.isReloadViewBatchScope;
    },
    triggerReloadManageBatchScope: state => {
      state.isReloadManageBatchScope = !state.isReloadManageBatchScope;
    },
    triggerIsLoadingAdminMiniDashboard: (state, action) => {
      state.isLoadingAdminMiniDashboard = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getMiniDashboard.fulfilled, (state, action) => {
      state.listMiniDasboard = action.payload;
      state.isLoadingMiniDashboard = false;
      state.isDisableTestCaseWithDashboard = !state.isDisableTestCaseWithDashboard;
    });

    builder.addCase(getBatchExecutionDetails.fulfilled, (state, action) => {
      state.listBatchExecutionDetails = action.payload;
      state.isLoadingBatchExecution = false;
      state.isDisableTestCaseWithBatchExecutionDetail = !state.isDisableTestCaseWithBatchExecutionDetail;
    });
  },
});

export const {
  setBatchSelectedId,
  triggerUpdateBatch,
  setBatchSelectedDetail,
  setBatchConfiguringDetail,
  setBatchSetting,
  setDefectTriageDetail,
  setViewBatchScren,
  triggerUpdateBatchSummary,
  triggerUpdateDefectTriage,
  triggersLoadingMiniDashboard,
  triggersLoadingBatchExecution,
  triggersIsDisableTestCaseWithBatchExecutionDetail,
  triggersIsDisableTestCaseWithDashboard,
  triggerReloadViewBatchScope,
  triggerReloadManageBatchScope,
  clearBatchSummary,
  triggerIsLoadingAdminMiniDashboard,
} = authoriseBatchSlice.actions;

export default authoriseBatchSlice.reducer;
