import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import { IMiniDashboard, IRequirementType } from 'app/models/userStories.model';
import {
  addNewRequirementAPI,
  apiGetMiniDashboard,
  excutionStatusAPI,
  getRequirementDetailAPI,
  getRequirementGridChartAPI,
  getRequirementTypeAPI,
  issueStatusAPI,
  updateRequirementAPI,
} from 'app/services/api/userStories';
import { DELETED_ID } from 'types/Common/DeletedID';

export interface UserStoriesState {
  isLoading: boolean;
  isUpdateRequirement: boolean;
  requirementSelectedId: number;
  RequirementDetail: any;
  listMiniDasboard: IMiniDashboard[];
  isLoadingMiniDashboard: boolean;
  listExcutionStatus: any;
  isLoadingExecutionStatus: boolean;
  listIssueStatus: any;
  isLoadingIssueChart: boolean;
  requiremnetType: IRequirementType[];
  isAssignToMe: boolean;
  isRefreshHistory: boolean;
  isUpdateMiniDashBoard: boolean;
}

const initialState: UserStoriesState = {
  isLoading: false,
  isUpdateRequirement: false,
  requirementSelectedId: 0,
  RequirementDetail: {},
  listMiniDasboard: [],
  isLoadingMiniDashboard: false,
  listExcutionStatus: {},
  isLoadingExecutionStatus: false,
  listIssueStatus: {},
  isLoadingIssueChart: false,
  requiremnetType: [],
  isAssignToMe: true,
  isRefreshHistory: false,
  isUpdateMiniDashBoard: false,
};

export const getRequirementDetail = createAsyncThunk('release/getRequirementDetail', async (params: any) => {
  const response = await getRequirementDetailAPI(params);
  return response.data;
});

export const addNewRequirement = createAsyncThunk('release/addNewRequirement', async (params: any) => {
  try {
    const res: any = await addNewRequirementAPI(params);
    if (res) {
      return res.data;
    }
  } catch (error: any) {
    openNotificationWithIcon('error', typeof error == 'string' ? error : error?.message);
  }
});

export const updateRequiremnet = createAsyncThunk('release/updateRequiremnet', async (params: any) => {
  try {
    const res: any = await updateRequirementAPI(params);
    if (res) {
      openNotificationWithIcon('success', res.data);
      return res.data;
    }
  } catch (error: any) {
    openNotificationWithIcon('error', typeof error == 'string' ? error : error?.message);
  }
});

// Call API Chart
export const getRequirementMiniDashboard = createAsyncThunk(
  'release/getRequirementMiniDashboard',
  async (id: number) => {
    const response = await apiGetMiniDashboard(id);
    return response.miniDashboard;
  },
);

export const getRequirementExcutionStatus = createAsyncThunk(
  'release/getRequirementExcutionStatus',
  async (id: number) => {
    const response = await excutionStatusAPI(id);
    return response.executionStatus;
  },
);

export const getRequirementIssueStatus = createAsyncThunk('release/getRequirementIssueStatus', async (id: number) => {
  const response = await issueStatusAPI(id);
  return response;
});

export const getRequirementType = createAsyncThunk('release/getRequirementType', async () => {
  const response = await getRequirementTypeAPI();
  return response;
});

export const userStoriesSlice = createSlice({
  name: 'user-stories',
  initialState,
  reducers: {
    setRequirementSelectedId: (state, action) => {
      state.requirementSelectedId = action.payload;
    },
    triggerUpdateRequirement: state => {
      state.isUpdateRequirement = !state.isUpdateRequirement;
    },
    clearRequirementDetail: state => {
      state.RequirementDetail = null;
    },
    editRequirement: (state, action) => {
      state.RequirementDetail = {
        ...state.RequirementDetail,
        [action.payload.fieldName]: action.payload.newValue,
      };
    },
    triggerLoadingMiniChart: state => {
      state.isLoadingMiniDashboard = !state.isLoadingMiniDashboard;
    },
    triggerLoadingExecutionChart: state => {
      state.isLoadingExecutionStatus = !state.isLoadingExecutionStatus;
    },
    triggerLoadingIssueChart: state => {
      state.isLoadingIssueChart = !state.isLoadingIssueChart;
    },
    triggerUpdateRequirementDeatil: (state, action) => {
      if (action.payload == 0) {
        state.RequirementDetail = {};
      }
    },
    setAssignToMe: (state, action) => {
      state.isAssignToMe = action.payload;
    },
    setIsUpdateMiniDashBoard: (state, action) => {
      state.isUpdateMiniDashBoard = action.payload;
    },
    triggerUpdateHistory: state => {
      state.isRefreshHistory = !state.isRefreshHistory;
    },
  },
  extraReducers: builder => {
    builder.addCase(getRequirementDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.RequirementDetail = action.payload.requirementDetail;
    });

    builder.addCase(getRequirementDetail.rejected, (state, action) => {
      if (action?.error?.message?.includes('has been deleted')) {
        state.requirementSelectedId = DELETED_ID;
        state.RequirementDetail = null;
      } else {
        state.requirementSelectedId = 0;
      }
      state.isUpdateRequirement = !state.isUpdateRequirement;
    });

    builder.addCase(getRequirementType.fulfilled, (state, action) => {
      state.isLoading = false;
      state.requiremnetType = action.payload.requirementType;
    });

    builder.addCase(getRequirementMiniDashboard.fulfilled, (state, action) => {
      state.listMiniDasboard = action.payload;
      state.isLoadingMiniDashboard = false;
    });

    builder.addCase(getRequirementExcutionStatus.fulfilled, (state, action) => {
      state.listExcutionStatus = action.payload;
      state.isLoadingExecutionStatus = false;
    });

    builder.addCase(getRequirementIssueStatus.fulfilled, (state, action) => {
      state.listIssueStatus = action.payload;
      state.isLoadingIssueChart = false;
    });
  },
});

export const {
  setRequirementSelectedId,
  triggerUpdateRequirement,
  editRequirement,
  triggerLoadingMiniChart,
  triggerLoadingExecutionChart,
  triggerLoadingIssueChart,
  triggerUpdateRequirementDeatil,
  clearRequirementDetail,
  setAssignToMe,
  triggerUpdateHistory,
  setIsUpdateMiniDashBoard,
} = userStoriesSlice.actions;

export default userStoriesSlice.reducer;
