import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import './Notifications.scss';
import { createServiceRequestAPI } from 'app/services/api/home';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
const ServiceRequest = (props: any) => {
  const [serviceRequestContent, setServiceContent] = useState('');

  const onSubmitServiceRequest = () => {
    if (serviceRequestContent)
      createServiceRequestAPI(serviceRequestContent)
        .then(res => {
          openNotificationWithIcon('success', res.message);
          props.onCancelModal();
          setServiceContent('');
        })
        .catch(error => {
          openNotificationWithIcon('error', error);
        });
  };
  return (
    <>
      <Modal
        className="modal-notification"
        destroyOnClose
        open={props.isOpenServiceRequest}
        onCancel={props.onCancelModal}
        footer={null}
      >
        <div className="service-request">
          <div className="service-request__icon">
            <img src="./image/service-request-icon.svg" alt="service-request-icon" />
          </div>
          <div className="service-request__title" role="title">
            Service Request
          </div>
          <div className="service-request__description">
            Please enter the service request details being as specific as possible, up to a maximum of 3,200 characters
          </div>
          <div className="service-request__content">
            <Input
              id="service-request__content__input"
              className="service-request__content__input"
              maxLength={3200}
              onChange={e => setServiceContent(e.target.value)}
            />
            <Button
              type="primary"
              id="service-request__content__submit"
              className="service-request__content__submit"
              onClick={() => onSubmitServiceRequest()}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ServiceRequest;
