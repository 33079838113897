export enum ETitleReleasePage {
  release_detail = 'Release Detail',
  release_issue_status = 'Release Issue Status',
  mini_dashboard = 'Mini-Dashboard',
  releases_execution_status = 'Release Execution Status',
  activity = 'Activity',
  workflow = 'Workflow',
}

export interface ILabelActivity {
  id: number;
  value: string;
  name: string;
}

export const labelActivity = ['History', 'Manage Release Scope', 'Manage Defect Coverage', 'Attachments'];
export const labelUserStoriesActivity = ['History', 'Manage test case coverage', 'Attachments'];
export enum titleActivity {
  history = 'History',
  manage_release_ccope = 'Manage Release Scope',
  manage_defect_coverage = 'Manage Defect Coverage',
  attachments = 'Attachments',
  manage_test_case_coverage = 'Manage Test Case Coverage',
}

export enum miniDashboard {
  openDefect = 'OpenDefect',
  days_remaining_in_release = 'Days Remaining in Release',
  test_excution = 'Test Excution',
  complete = 'Complete',
}

export enum miniDashboardAdminReleases {
  summary = 'Release Summary',
  test_excution = 'Test Execution',
  open_defects = 'Open Defects',
  residual_release_risk = 'Residual Release Risk',
}

export enum miniDashboardRequirement {
  test_coverage = 'Test Coverage',
  test_excution = 'Test Execution',
  defects = 'Defects',
  residual_risk = 'Residual Risk',
}

export enum miniDashboardAdminRequirement {
  summary = 'Requirements Summary',
  test_excution = 'Test Execution',
  open_defects = 'Open Defects',
  residual_release_risk = 'Residual Release Risk',
}

export enum miniDashboardAdminWorbench {
  test_case_priority = 'Test Case Priority',
  test_case_status = 'Test Case Status',
  new_open_defects = 'New/Open Defects',
}

export enum miniDashboardDefect {
  residual_risk = 'Residual Risk',
  not_type = 'Not Type',
  days_remaining_in_release = 'Days Remaining in Release',
}

export enum miniDashboardAdminDefect {
  avg_residual_risk = 'Avg Residual Risk',
  avg_days = 'Avg Days',
  bounce_reopen = 'Bounce & Reopen',
  open_defects = 'Open Defects',
}

export enum miniDashboardTestCase {
  workbench_test_case = 0,
  enginuity_workbench_test_case = 1,
}

export const labelWorkflow = ['Created', 'Assigned', 'Modified', 'Approved', 'Deployed'];
export const workflowDefect = ['fix in progress', 'ready for retest', 'reassign', 'escalate'];
export const workflowAssignWorkbench = ['rework', 'approved', 'reassign'];
export enum titleWorkflow {
  draft = 'Draft',
  ready_for_review = 'Ready For Review',
  rework_required = 'Rework Required',
  approved = 'Approved',
  reassign = 'Reassign',
  escalate = 'Escalate',
  deploy_release = 'Deploy Release',
  deprecate = 'Deprecate',
  fixed = 'Fix in Progress',
  ready_for_retest = 'Ready for Retest',
  reopen_defect = 'Reopen Defect',
  defer_defect = 'Defer Defect',
  close_defect = 'Close Defect',
  for_review = 'For Review',
  rework = 'Rework',
  retired = 'Retired',
}

export enum testExecution {
  not_run = '% Not run',
  in_progress = '% In Progress',
  passed = '% Passed',
}
export const releaseExecutionStatusTitleTable = [' ', 'Tesst Cases', 'Tesst', 'Conditions', 'Tesst Steps'];
export const historyColumns = ['Field', 'Timestamp', 'Modified By', 'Old Value', 'New Value'];
export const chartColor = ['#739EF9', '#33B539', '#F6B168', '#13C2C2', '#FACC14', '#6249F8', '#54D59A', '#7784A0'];

export enum EntityType {
  Testcase = 1,
  Release = 2,
  AcceptanceCriteria = 3,
  Requirement = 4,
  Defect = 5,
  Batch = 6,
  DynamicImage = 7,
  Item = 8,
  Scenario = 9,
  TestCondition = 10,
}

export const defectMiniDashboard = [
  'Days Open',
  'Days Assigned',
  'Bounce Rate',
  'Re - Opens',
  'Calendar Days',
  'Business Days',
];

export const reloadMiniDashboarDefect = ['severity', 'priority', 'frequency', 'releaseId'];
export const initAddWorkbench = ['Add New', 'Clone A Test'];
// export const initAdminFunc = ['Licence Management', 'Roles and Permission'];

export const initAddBatchSummary = ['Add New', 'Clone A Copy'];
export const initCloneCopyBatchSummary = [
  {
    id: 1,
    type: 1,
    label: 'All Tests',
  },
  {
    id: 2,
    type: 2,
    label: 'Fail and Incomplete Tests',
  },
  {
    id: 3,
    type: 3,
    label: 'Only Failed Tests',
  },
  {
    id: 4,
    type: 4,
    label: 'Only Incomplete Tests',
  },
];
export const initEnginuityWorkbench = ['Add New', 'Clone A Test', 'Create A Variant'];

export enum TestCaseOperationModeEnum {
  Manual = 0,
  Automated = 1,
}
